@use '@angular/material' as mat;

@import "conf/variables";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

:root {
    --mdc-filled-text-field-container-color: transparent;
    --mdc-filled-text-field-label-text-size: 14px;
    --mdc-filled-text-field-focus-active-indicator-height: 1px;
    --mdc-filled-text-field-active-indicator-color: #0000001f;
    --mdc-filled-text-field-label-text-color: #0000008a;
    --mdc-filled-text-field-label-text-tracking: normal;
    --mdc-filled-text-field-label-text-font: $font-family;
    --mat-form-field-container-vertical-padding: 0.4375em;
    --mat-form-field-container-text-size: 14px;
    --mat-form-field-filled-with-label-container-padding-top: 1em;
    --mat-form-field-filled-with-label-container-padding-bottom: 0.4em;
    --mdc-icon-button-icon-size: 14px;
}

$aqua-palette: mat.define-palette((50: rgba($aqua, 0.1),
            100: rgba($aqua, 0.2),
            200: rgba($aqua, 0.4),
            300: rgba($aqua, 0.6),
            400: rgba($aqua, 0.8),
            500: $aqua,
            600: $aqua,
            700: $aqua,
            800: $aqua,
            900: $aqua,
            A100: $aqua,
            A200: $aqua,
            A400: $aqua,
            A700: $aqua,
            contrast: (50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: rgba(white, 0.87),
                600: rgba(white, 0.87),
                700: rgba(white, 0.87),
                800: rgba(white, 0.87),
                900: rgba(white, 0.87),
                A100: rgba(black, 0.87),
                A200: white,
                A400: white,
                A700: rgba(white, 0.87),
            )));

$green-palette: mat.define-palette((50: rgba($green, .1),
            100: rgba($green, .2),
            200: rgba($green, .4),
            300: rgba($green, .6),
            400: rgba($green, .8),
            500: $green,
            600: $green,
            700: $green,
            800: $green,
            900: $green,
            A100: $green,
            A200: $green,
            A400: $green,
            A700: $green,
            contrast: (50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: rgba(white, 0.87),
                600: rgba(white, 0.87),
                700: rgba(white, 0.87),
                800: rgba(white, 0.87),
                900: rgba(white, 0.87),
                A100: rgba(black, 0.87),
                A200: white,
                A400: white,
                A700: rgba(white, 0.87),
            )));

$black-palette: mat.define-palette((50: rgba(#000, .1),
            100: rgba(#000, .2),
            200: rgba(#000, .4),
            300: rgba(#000, .6),
            400: rgba(#000, .8),
            500: #000,
            600: #000,
            700: #000,
            800: #000,
            900: #000,
            A100: #000,
            A200: #000,
            A400: #000,
            A700: #000,
            contrast: (50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: rgba(white, 0.87),
                600: rgba(white, 0.87),
                700: rgba(white, 0.87),
                800: rgba(white, 0.87),
                900: rgba(white, 0.87),
                A100: rgba(black, 0.87),
                A200: white,
                A400: white,
                A700: rgba(white, 0.87),
            )));

$red-palette: mat.define-palette((50: $danger,
            100: $danger,
            200: $danger,
            300: $danger,
            400: $danger,
            500: $danger,
            600: $danger,
            700: $danger,
            800: $danger,
            900: $danger,
            A100: $danger,
            A200: $danger,
            A400: $danger,
            A700: $danger,
            contrast: (50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: rgba(white, 0.87),
                600: rgba(white, 0.87),
                700: rgba(white, 0.87),
                800: rgba(white, 0.87),
                900: rgba(white, 0.87),
                A100: rgba(black, 0.87),
                A200: white,
                A400: white,
                A700: rgba(white, 0.87),
            )));

$black-theme: mat.define-light-theme((
    color: (
        primary: $black-palette,
        accent: $green-palette,
        warn: $red-palette,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));
$aqua-theme: mat.define-light-theme((
    color: (
        primary: $aqua-palette,
        accent: $green-palette,
        warn: $red-palette,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

@include mat.core-theme($black-theme);
@include mat.autocomplete-theme($black-theme);
@include mat.button-theme($black-theme);
@include mat.fab-theme($black-theme);
@include mat.icon-button-theme($black-theme);
@include mat.button-toggle-theme($black-theme);
@include mat.card-theme($black-theme);
@include mat.checkbox-theme($black-theme);
@include mat.chips-theme($black-theme);
@include mat.datepicker-theme($black-theme);
@include mat.dialog-theme($black-theme);
@include mat.grid-list-theme($black-theme);
@include mat.icon-theme($black-theme);
@include mat.form-field-theme($black-theme);
@include mat.input-theme($black-theme);
@include mat.list-theme($black-theme);
@include mat.menu-theme($black-theme);
@include mat.progress-bar-theme($aqua-theme);
@include mat.progress-spinner-theme($black-theme);
@include mat.radio-theme($black-theme);
@include mat.select-theme($black-theme);
@include mat.sidenav-theme($black-theme);
@include mat.slide-toggle-theme($black-theme);
@include mat.slider-theme($black-theme);
@include mat.tabs-theme($black-theme);
@include mat.toolbar-theme($black-theme);
@include mat.tooltip-theme($black-theme);

// Font

[class^="mat-"] {
    font-family: $font-family !important;
}

// Input

.mat-mdc-floating-label {
    text-transform: uppercase;
}

.mat-mdc-form-field {
    display: block !important;
    background-color: transparent !important;

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0 !important;
    }
}

.mat-form-field-appearance-fill {
    background-color: rgba(0, 0, 0, 0.12);

    &.without-underline {
        .mdc-line-ripple {
            display: none;
        }
    }

    .mat-mdc-form-field-error-wrapper {
        padding: 0 !important;
    }

    .mat-mdc-form-field-infix {
        box-sizing: content-box;
        min-height: 1em;
    }

    .mdc-floating-label {
        height: var(--mat-form-field-filled-with-label-container-padding-top);
        margin-bottom: 0;
        cursor: text !important;
        top: calc(var(--mat-form-field-filled-with-label-container-padding-top) * 2) !important;

        &.mdc-floating-label--float-above {
            $test: calc(var(--mat-form-field-filled-with-label-container-padding-top) * -1.75);
            transform: translateY($test) scale(0.70) !important;
        }
    }
}



.mdc-text-field {
    padding-right: 0 !important;
    padding-left: 0 !important;

    // Fixes positioning for the floating labels.

}

.mat-mdc-form-field-focus-overlay {
    display: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 1px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-subscript-wrapper {
    font-size: 11px;
}

.cdk-textarea-autosize {
    min-height: 36px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.without-underline .mat-mdc-form-field-underline {
    display: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
[floatLabel="never"] .mat-form-field-label {
    color: rgba(0, 0, 0, 0.38) !important;
    text-transform: none;
    font-style: italic;
}

[floatLabel="never"] .mat-mdc-input-element {
    caret-color: black;
}

// Legacy input

.error-message-legacy {
    display: none;
}

.has-error-legacy {
    position: relative;
}

.has-error-legacy .error-message-legacy {
    color: $danger;
    font-size: 11px;
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.has-error-legacy .mat-form-field-label,
.has-error-legacy .mat-input-element:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.has-error-legacy .mat-placeholder-required {
    color: $danger;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.has-error-legacy .mat-form-field-underline {
    background-color: $danger;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.has-error-legacy .mat-form-field-ripple {
    opacity: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.has-error-legacy .mat-form-field-subscript-wrapper {
    display: none;
}

// Dialog

.cdk-overlay-pane {
    max-width: unset !important;

    &.theme-error .mat-mdc-dialog-container {
        background-color: $danger;
        color: #fff;
    }

    &.theme-warning .mat-mdc-dialog-container {
        background-color: $warning;
        color: #fff;
    }

    &.theme-success .mat-mdc-dialog-container {
        background-color: $success;
        color: #fff;
    }

    &.theme-info .mat-mdc-dialog-container {
        background-color: $info;
        color: #fff;
    }
}

.mat-mdc-dialog-container {
    transform: none !important;
    padding: 0 !important;
    max-width: none !important;
    overflow: visible !important;
    border-radius: 0 !important;
}

.mat-mdc-menu-panel.disable-padding .mat-mdc-menu-content {
    padding: 0;
}

// Snack bar
.mat-mdc-snack-bar-container {
    border-radius: 0 !important;
    color: #fff;

    &.snack-bar-error {
        background-color: $danger;
    }

    &.snack-bar-warning {
        background-color: $warning;
    }

    &.snack-bar-success {
        background-color: $success;
    }

    &.snack-bar-info {
        background-color: $info;
    }
}

// Menu

.mat-mdc-menu-item {
    font-size: 14px !important;
    line-height: 42px !important;
    height: 42px !important;

    &.selected {
        color: $blue;
    }
}

// Chips

.mat-mdc-chip:not(.mat-mdc-basic-chip) {
    margin: 0 3px 3px 0 !important;
}

.mat-mdc-chip.mat-chip-selected:not(.mat-mdc-basic-chip).mat-primary {
    color: #fff;
}

.mat-mdc-standard-chip {
    &.mdc-evolution-chip--with-primary-graphic {
        .mdc-evolution-chip__action--primary {
            padding: 0 12px !important;
        }
    }
}

.mat-mdc-chip.mdc-evolution-chip--selected {
    .mdc-evolution-chip__action {
        padding-left: 12px;
    }

    .mat-mdc-chip-graphic {
        display: none;
    }

    .mat-remove {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 10px;
        cursor: pointer;

        svg {
            fill: #fff;
            display: block;
        }
    }
}

.mat-mdc-select-panel,
.mat-mdc-menu-panel {
    transform: translateZ(0);
    background: #fff;
    border-radius: 0 !important;
    min-height: 0 !important;
    max-width: 100vw !important;
}
