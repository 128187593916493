.ngx-mat-timepicker .mat-mdc-input-element {
    font-size: 14px;
}

.ngx-mat-timepicker .mat-mdc-form-field {
    width: 24px !important;
    max-width: 24px !important;
}

.ngx-mat-timepicker .ngx-mat-timepicker-spacer {
    padding-bottom: 8px;
}

.ngx-mat-timepicker .mat-mdc-icon-button {
    color: rgba(0, 0, 0, 0.54);
}

.ngx-mat-timepicker .mat-icon {
    display: none;
}

.ngx-mat-timepicker .ngx-mat-timepicker-table tr:first-child .mat-mdc-icon-button {
    bottom: -7px;
}

.ngx-mat-timepicker .ngx-mat-timepicker-table tr:first-child .mat-mdc-icon-button::after {
    top: 9px;
    left: 6px;
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    border: 0 solid currentColor;
    border-top-width: 2px;
    border-left-width: 2px;
    transform: translate(2px) rotate(45deg);
}

.ngx-mat-timepicker .ngx-mat-timepicker-table tr:last-child .mat-mdc-icon-button {
    top: -10px;
}

.ngx-mat-timepicker .ngx-mat-timepicker-table tr:last-child .mat-mdc-icon-button::after {
    top: 6px;
    left: 6px;
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    border: 0 solid currentColor;
    border-top-width: 2px;
    border-left-width: 2px;
    transform: translate(2px) rotate(225deg);
}
